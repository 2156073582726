import React, { useEffect, useState, useRef } from 'react';
import PortableText from '@sanity/block-content-to-react';
import { StaticImage } from 'gatsby-plugin-image';
import PropTypes, { number } from 'prop-types';
import { useInView } from "react-intersection-observer";
import {
  StyledForm, Quantity, QuantityButton, NumberInput, StyledButton, SelectorContainer, VariantSelect, 
  SelectWrapper, BundleSubheader, CaseSubheader, ChooseOffer, OutOfStock, MobileBundlePopUp, FenixContainer, 
  FenixResponse, InputQuantity, OutOfStockTitle, CaseAtcButtonWrapper, DeliveryTimeWidget, CheckoutButton, BuyItNowButton
} from './styles';
import { encode, decode } from 'shopify-gid';
import ArrowDown from 'src/images/arrow_down.svg';
import ArrowUp from 'src/images/arrow_up.svg';
import truck from 'src/images/truck_tick_mark.svg';
import {
  client, useAddItemToCart, useUpsells, useCartDrawer, useStore,
  usePillowcaseMaterialDrawer, addToCartRedirectToCheckout, useCart
} from 'src/context/siteContext';
import Icon from 'src/components/Common/Icon';
import UpsellPopup from 'src/components/Upsells/UpsellPopup';
import { SanityImage } from 'src/interfaces/sanity';
import { PackType } from 'src/components/ProductInfo';
import Image from 'src/components/Common/ImageFluid';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import _ from 'lodash';
import Helmet from 'react-helmet';
import {
  BundleHeader, CaseHeader, BundleContainer, BundleWrap, Badge, BadgeBundle, CaseContainer, CaseCard,
  CardImageContainer, CardDetail, CaseTitle, CasePrice, CaseLink, AtcPillow,
  NewBundleCard,QuantityHeader,QuantityContainer,QuantityCard,QuantityWrap,
} from '../ProductInfo/styles';
import Cart from 'src/images/cart.svg';
import { eventData } from 'src/utils/dataLayer';
import { ___fenixRecallApi, getFullDay } from 'src/utils/fenixWidget';
import DeliveryTime from "src/containers/DeliveryTime";
import PaymentIcon from "src/images/newPaymentIcons.svg";
import PaymentIconMobile from "src/images/payment_buttons_2rows.svg"
import classNames from 'classnames';
import NewGift from "src/images/giftt.svg";

import { useDiscount } from 'src/context/siteContext';
import trackKlavyioAddedToCart from 'src/utils/mapKlaviyoPayload';
import PaymentIcons from '../PaymentIcons';
import CustomerTestimonial from '../CustomerTestimonial';
interface Props {
  id: string;
  title: string;
  sku: string;
  variantTitle: string;
  defaultPrice: string;
  slug?: {
    current: string;
  };
  type: any;
  productId: number;
  waitlist?: boolean | true;
  showQuantity?: boolean | true;
  addText?: string;
  activePack?: PackType;
  setActivePack: any;
  removePack: () => void;
  addQuantity: any;
  removeQuantity: any;
  removeQuantityBundle: any;
  quantity: any;
  setQuantity: any;
  mobilePhoto?: SanityImage;
  setComparePrice: any;
  setDiscountedPrice: any;
  discountedPrice: any;
  setDiscount: any;
  setProductPack: any;
  bundles?: [];
  pillowcases?: [];
  productQuantity?: [];
  quantityText?: string;
  caseText?: string;
  discount?: number;
  caseSubheader?: [];
  atcButton?: {
    caption?: string;
    price?: boolean;
  };
  upsellReviews?: any;
  setOpenUpsell: (state: boolean) => void;
  bundleIcon: any;
  bundleText: string;
  bundleSubheader: string;
  backgroundColor: {
    title: string;
    value: string;
  };
  pillowcasePrice: (price: any) => void;
  showDeliveryTimeWidget: boolean;
  showBuyNowButton: boolean;
  enableProdBundles?: boolean;
  enableProdQuantity?: boolean;

}

const getDimensions = (el) => {
  const { height } = el.getBoundingClientRect();
  const offsetTop = el.offsetTop;
  const offsetBottom = offsetTop + height;

  return {
    height,
    offsetTop,
    offsetBottom,
  };
};

const ProductForm = ({
  id,
  title,
  sku,
  variantTitle,
  slug,
  type,
  defaultPrice,
  productId,
  showQuantity,
  waitlist = true,
  addText,
  activePack,
  setProductPack,
  setActivePack,
  removePack,
  addQuantity,
  removeQuantity,
  removeQuantityBundle,
  quantity,
  setQuantity,
  mobilePhoto,
  atcButton,
  announcementBarProduct,
  setComparePrice,
  setDiscountedPrice,
  discountedPrice,
  setDiscount,
  mainImage,
  upsellData,
  upsellReviews,
  bundles,
  pillowcases,
  productQuantity,
  quantityText,
  discount,
  caseText,
  caseSubheader,
  setSavedMoney,
  bundleIcon,
  bundleText,
  bundleSubheader,
  backgroundColor,
  pillowcasePrice,
  showDeliveryTimeWidget,
  showBuyNowButton,
  enableProdBundles,
  enableProdQuantity
}: Props) => {
  const { addItemToCart } = useAddItemToCart();
  const { checkout } = useCart();
  const seeMaterialRef = useRef(null);
  const addPillowcaseQuantityRef = useRef(null);
  const removePillowcaseQuantityRef = useRef(null);
  const { openUpsells, closeUpsells } = useUpsells();
  const { upsells } = useStore();
  const { openCart } = useCartDrawer();
  const { openPillowcaseMaterial } = usePillowcaseMaterialDrawer();
  const breakpoints = useBreakpoint();
  const [adding, setAdding] = useState(false as boolean);
  const [price, setPrice] = useState(defaultPrice);
  const [available, setAvailable] = useState(true);
  const [offerChose, setOfferChose] = useState(false);
  const [openOffers, setOppenOffers] = useState(false);
  const [offerChoice, setOfferChoice] = useState(0);
  const [variants, setVariants] = useState([]);
  const [activeVariantId, setActiveVariantId] = useState('' as string);
  const [pillowcaseQuantity, setPillowcaseQuantity] = useState(0);
  const [compareAtPrice, setCompareAtPrice] = useState(
    undefined as string | undefined
  );
  const [check, setCheck] = useState(true);
  const [mobileFixed, setMobileFixed] = useState(false);
  const [activeBundle, setActiveBundle] = useState();
  const [activePillow, setActivePillow] = useState();
  const [activeUpsell, setActiveUpsell] = useState();
  const [modalCollapse, setModalCollapse] = useState(false);
  const [availableQuantity, setAvailableQuantity] = useState([]);
  const [currentDiscount, setCurrentDiscount] = useState(0);
  const [fenixData, setFenixData] = useState([]);
  const [availBundles, setAvailBundles] = useState([]);
  const [formHeight, setFormHeight] = useState(0);
  const [isProductWithPillowcase, setIsProductWithPillowcase] = useState(
    false as boolean
  );
  const [availPillowcases, setAvailPillowcases] = useState([]);
  const [activePillowcase, setActivePillowcase] = useState({
    variantId: '',
    quantity: 0,
    isOpen: false,
  });
  const form = useRef();
  const { addDiscount } = useDiscount();
  const hasAvailableVariant = variants.some(item => !!item.available)
  let availableVariantinVariants;
  variants.map(variant => {
    if(variant.available) {
      availableVariantinVariants = variant;
    }
  });

  const sessionId = Math.random()
    .toString(36)
    .substr(2, 25); // Unique request

  useEffect(() => {
    setFormHeight(form?.current?.clientHeight);
  }, [availableQuantity.length]);

  useEffect(() => {
    if (check) {
      setCartInfo(productId);
      const defaultUpsell = availBundles?.length
        ? availBundles[0]?.bundleUpsell
        : upsellData;
      setActiveUpsell(defaultUpsell);
    }
  }, [check]);

  useEffect(() => {
    if (mobileFixed && breakpoints.tablet) {
      setMobileFixed(false);
    }

    if (breakpoints.tablet === undefined || breakpoints.tablet) return;

    const productHero = document.getElementById('product-hero');

    const scrollFunction = _.throttle(() => {
      if (!productHero) return;

      const scrollPos = window.scrollY;
      const innerHeight = window.innerHeight;
      const dimensions = getDimensions(productHero);

      if (
        scrollPos + innerHeight >=
        dimensions.offsetBottom + 7 * dimensions.offsetTop
      ) {
        setMobileFixed(true);
      } else {
        setMobileFixed(false);
      }
    }, 100);

    window.addEventListener('scroll', scrollFunction);

    return () => window.removeEventListener('scroll', scrollFunction);
  }, [breakpoints.tablet]);

  useEffect(() => {
    const availableBundles = bundles?.filter((bundle) => !bundle?.testB);

    if (availableBundles && availableBundles.length > 0) {
      const defaultBundle = availableBundles.find(
        (bundle) => bundle?.isDefault
      );
      if (defaultBundle) {
        setDiscount(defaultBundle?.discount);
        setActiveBundle(defaultBundle);
      } else {
        setDiscount(availableBundles[0]?.discount);
      }
      setAvailBundles(availableBundles);
    }
    if (pillowcases && pillowcases.length > 0) {
      setAvailPillowcases(pillowcases);
      setIsProductWithPillowcase(true);
    }
  }, [bundles, pillowcases]);

  useEffect(() => {
    if (productQuantity && productQuantity.length > 1) {
      setAvailableQuantity(productQuantity);
    }
  }, [productQuantity]);

  useEffect(() => {
    if (quantity && availableQuantity.length > 0) {
      setCurrentDiscount(availableQuantity[quantity - 1]?.discount);
    }
  }, [quantity, availableQuantity.length]);

  useEffect(() => {
    if (currentDiscount) {
      const comparePrice = parseFloat(
        (100 / (100 - currentDiscount)) * price
      ).toFixed(2);
      setDiscount(currentDiscount);
      setComparePrice(comparePrice);
    }
  }, [currentDiscount, price]);

  useEffect(() => {
    const clientLocation = window.getClientLocation;
    if (
      clientLocation?.country_code === 'US' ||
      clientLocation?.country_code === 'GB'
    ) {
      const requestData = {
        sessionTrackId: sessionId,
        buyerZipCode: clientLocation?.zip,
        monetaryValue: price,
        pageType: 'PDP',
        responseFormat: 'json',
        skus: [
          {
            sku: sku,
            quantity: 1,
          },
        ],
      };
      ___fenixRecallApi(requestData)
        .then((res) => setFenixData(res.data))
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {
    const totalPillowPrices = activePillow
      ? activePillow?.content?.shopify?.defaultPrice * pillowcaseQuantity
      : 0;
    pillowcasePrice(totalPillowPrices);
  }, [activePillow, pillowcaseQuantity]);

  const trackATC = () => {
    const item = {
      name: title,
      id: variants[0]?.sku,
      image: variants[0]?.image?.src,
      product_id: id,
      price: parseFloat(price),
      brand: 'GroovePillows',
      variant: variants[0]?.title,
      quantity: quantity,
    };
    dataLayer?.push({
      ...eventData('add_to_cart'),
      ecommerce: {
        currencyCode: process.env.GATSBY_CURRENCY_SYMBOL == '$' ? 'USD' : 'GBP',
        add: {
          products: [item]
        },
      },
    });
    // TriplePixel('AddToCart', { item: variants[0]?.sku, q: quantity });
    trackKlavyioAddedToCart(checkout, item);
  };

  const setCartInfo = (prodId: any) => {
    const shopifyId = encode('Product', prodId, {
      accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN,
    });

    client.product.fetch(shopifyId).then((product: any) => {
      const decodedVariants = [] as any;

      product?.variants?.forEach((variant: any) => {
        decodedVariants.push({
          ...variant,
          cleanId: parseInt((variant.id).id, 0),
        });
      });

      setVariants(decodedVariants);

      const availableVariant = decodedVariants.find((variant: any) => variant);

      setActiveVariantId(availableVariant?.id as string);
      setAvailable(availableVariant?.available);

      if (decodedVariants[0]?.compareAtPrice) {
        setCompareAtPrice(decodedVariants[0].compareAtPrice);
        setComparePrice(decodedVariants[0].compareAtPrice);
        setDiscountedPrice(decodedVariants[0].price);
      }

      check && setCheck(false);
    });

  };


  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!hasAvailableVariant) {
      return;
    }

    setAdding(true);

    const attributes = [];
    // only open pop-up when adding single pillow
    const upsellEnabled =
      activeUpsell?.enabled && activeUpsell?.productList?.length > 0;

    await addItemToCart(
      availableVariantinVariants.id,
      pillowcaseQuantity,
      attributes,
      upsellEnabled
    );

    await addItemToCart(availableVariantinVariants.id, quantity, attributes, upsellEnabled);

    setActivePillowcase({
      variantId: '',
      quantity: 0,
      isOpen: false,
    });
    setPillowcaseQuantity(0);
    setActivePillow(undefined);
    setAdding(false);

    if (!upsellEnabled) return;

    openUpsells({
      boughtItem: {
        id: activeBundle?.shopifyId || id,
        title: activeBundle?.title || title,
        quantity,
        price: activeBundle?.price || price,
        imageRef: mainImage,
      },
      productList: activeUpsell?.productList,
    });
  };

  const isUK = process.env.GATSBY_CURRENCY_SYMBOL !== "$";

  const getButtonCaption = () => {
    const bundleLength = productQuantity?.length;
    const lastItemPrice = productQuantity?.[bundleLength - 1]?.salesPrice;

    if (!atcButton?.caption && !productQuantity?.[quantity -1]?.salesPrice) {
      if (activePack) return `Buy for ${activePack.price}`;
      

      if (bundleLength < quantity) {
        const quantityOfNoDiscountProducts = quantity - bundleLength;
        const calculatedPriceWithDiscounts = (price * quantityOfNoDiscountProducts) + lastItemPrice;
        return `Buy for ${process.env.GATSBY_CURRENCY_SYMBOL +
          parseFloat(calculatedPriceWithDiscounts).toFixed(2)}`;
      }

      return `Buy for ${process.env.GATSBY_CURRENCY_SYMBOL +
        parseFloat(price * quantity).toFixed(2)}`;
    }

    if (!atcButton?.caption && productQuantity[quantity - 1]?.salesPrice) {
      if (activePack) return `Buy for ${activePack.price}`;

      return `Buy for ${process.env.GATSBY_CURRENCY_SYMBOL +
        parseFloat(productQuantity[quantity - 1]?.salesPrice).toFixed(2)}`;
    }

    if (atcButton?.price) {
      if (activePack) return `${atcButton?.caption} ${activePack.price}`;

      return `${atcButton?.caption} ${process.env.GATSBY_CURRENCY_SYMBOL +
        parseFloat(price * quantity).toFixed(2)}`;
    }

    return `${atcButton?.caption}`;
  };

  const handleChange = (e: React.FormEvent) => {  
    setActiveVariantId(e.target.value);
    variants.forEach((variant) => {
      if (variant.id === e.target.value) {
        if (variant.compareAtPrice) {
          setCompareAtPrice(variant.compareAtPrice);
          setComparePrice(decodedVariants[0].compareAtPrice);
        }
        setPrice(variant.price);
      }
    });
  };

  const handleBundle = (bundle: any) => {
    setActiveBundle(bundle);
    setDiscount(bundle.discount);
    const bundleProductId = bundle.shopifyId;
    setCartInfo(bundleProductId);
    setActiveUpsell(bundle.bundleUpsell);
  };

  const handlePillow = (pillow: any) => {
    const productId = pillow?.content?.shopify?.productId;

    const shopifyId = encode('Product', productId, {
      accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN,
    });

    client.product.fetch(shopifyId).then((product: any) => {
      const decodedVariants = [] as any;
      product?.variants?.forEach((variant: any) => {
        decodedVariants.push({
          ...variant,
          cleanId: parseInt((variant.id).id, 0),
        });
      });

      const availableVariant = decodedVariants.find((variant: any) => variant);

      if (availableVariant.available) {
        setPillowcaseQuantity(1);
        setActivePillow(pillow);
        setActivePillowcase({
          ...availableVariant,
          variantId: pillow?.content?.shopify?.defaultVariant?.variantIdShopify,
          isOpen: true,
        });
      }
    });

    check && setCheck(false);
  };

  // const handlePillowcaseQuantity = (event: any) => {
  //   console.log({ event });
  //   const value = event.target.value;
  //   const totalPrice =
  //     parseInt(defaultPrice, 10) + parseInt(value * activePillowcase.price, 10);
  //   if (event.target.value < 1) {
  //     setActivePillowcase((prevState) => ({
  //       ...prevState,
  //       isOpen: false,
  //     }));
  //     setPillowcaseQuantity(0);
  //     setActivePillow(undefined);
  //   } else {
  //     setPillowcaseQuantity(parseInt(value));
  //   }
  // };

  // const addPillowcaseQuantity = () => {
  //   setPillowcaseQuantity(pillowcaseQuantity + 1);
  // };

  // const removePillowcaseQuantity = () => {
  //   if (pillowcaseQuantity > 1) {
  //     setPillowcaseQuantity(pillowcaseQuantity - 1);
  //   } else {
  //     setActivePillowcase((prevState) => ({
  //       ...prevState,
  //       isOpen: false,
  //     }));
  //     setActivePillow(undefined);
  //     setPillowcaseQuantity(0);
  //   }
  // };


  const [bundless, setBundles] = useState(bundles);

  useEffect(() => {
    const promises = bundles?.map((bundle: any) => {
      return checkBundle(bundle?.shopifyId).then(isBundleAvailable => {
        bundle.isActive = isBundleAvailable;
        return bundle;
      });
    });
    if (promises?.length) {
      Promise.all(promises).then((result) => {
        setBundles(result);
      });
    }
  }, [bundles]);
  

  const checkBundle = async (productId: any) => {
    const shopifyId = encode("Product", productId, {
      accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN,
    });

    return await client.product.fetch(shopifyId).then((product: any) => {
      const availableVariant = product?.variants?.find(
        (variant: any) => variant
      );
      return availableVariant?.available;
    });
  };

  const handleSubmitBundle = (shopifyId: number) => {
    setAdding(shopifyId);

    const shopifyPordId = encode("Product", shopifyId, {
      accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_TOKEN,
    });

    const upsellEnabled = upsells?.enabled && upsells?.productList.length > 0;

    client.product.fetch(shopifyPordId).then(async (product: any) => {
      const availableVariants = product.variants.find((variant: any) => variant);
      await addItemToCart(availableVariants?.id, 1, [], upsellEnabled);
      setAdding(0);

      if (!upsellEnabled) return;

      openUpsells({
        boughtItem: { id, title, quantity, price, imageRef: mainImage },
        productList: upsells?.productList,
      });
    });
  };

  const openCheckout = addToCartRedirectToCheckout();
  useEffect(() => {
    $("#buy-it-now-button").click(function () {
      $("#backdrop-container").css("opacity", "0");
      $("#drawer-container").css("opacity", "0");
    })
  }, []);


  const [openBundle, setOpenBundle] = useState('');
  const toggle = (id) => setOpenBundle(id);

  const quantityLength = availableQuantity?.length;

  const isUS = process.env.GATSBY_CURRENCY_SYMBOL == "$";

  return (
    <>
      <div style={type == "productsShopify" ? { height: formHeight, display: mobileFixed ? 'block' : 'none' } : {height: "unset", display: "none"}}  />

      <StyledForm
        id="product-form"
        className={type == "productsShopify" ? "formInPDP" : "formInOtherPages"}
        onSubmit={(e) => handleSubmit(e)}
        ref={form}
        fixed={mobileFixed}
        modalCollapse={modalCollapse}
      >
        {mobileFixed && (
          <Helmet>
            <body
              style={`padding-bottom: ${variants.length > 1 ? '12.5rem' : '7rem'
                }`}
            />
          </Helmet>
        )}
        {mobileFixed && type=="productsShopify" && (
          <MobileBundlePopUp id="mobileBundle">
            See Offers
            <img
              src={modalCollapse ? ArrowUp : ArrowDown}
              width="35"
              height="35"
              onClick={() => setModalCollapse(!modalCollapse)}
            />
          </MobileBundlePopUp>
        )}

        {available || hasAvailableVariant ? (
          <>
            {variants.length > 1 && (
              <SelectWrapper>
                <VariantSelect onChange={handleChange}>
                  {variants.map(
                    ({
                      id,
                      title,
                      available,
                    }: {
                      id: string;
                      title: string;
                      available: boolean;
                    }) => (
                      <option disabled={!available} key={id} value={id}>
                        {title}
                      </option>
                    )
                  )}
                </VariantSelect>
              </SelectWrapper>
            )}

            <FenixContainer>
              <SelectorContainer>
                {/*<h1 className="qty">Quantity:</h1>*/}
                <ChooseOffer>
                  {showQuantity && (
                    <Quantity>
                      <QuantityButton
                        aria-label="Decrease quantity" 
                        type="button"
                        onClick={() => {
                          if (quantity === 1) return;
                          removeQuantityBundle()
                        }}
                      >
                        <Icon name="minus-second" />
                      </QuantityButton>
                      <NumberInput
                        type="number"
                        aria-label="Quantity number"
                        value={quantity}
                        // onChange={(e) =>
                        //   setQuantity(parseInt(e.currentTarget.value, 10))
                        // }
                        name="quantity"
                        readOnly={true}
                        min="0"
                        max="999"
                      />
                      <QuantityButton
                        className="increase-quantity-button"
                        type="button"
                        aria-label="Increase quantity" 
                        onClick={() => {
                          if (quantity == Infinity) return;
                          addQuantity();
                        }}
                      >
                        <Icon name="plus-second" />
                      </QuantityButton>
                    </Quantity>
                  )}
                  {/* <div
                  onClick={() =>
                    openOffers ? setOppenOffers(false) : setOppenOffers(true)
                  }
                  >
                  {!openOffers && (
                    <div
                    onClick={() => setSavedMoney(false)}
                      className="chooseOffer"
                      >
                      {offerChoice}{" "}
                      <span>
                      <img src={ArrowDown} />
                      </span>
                      </div>
                      )}
                    </div> */}
                  {!openOffers && (
                    <StyledButton type="submit" onClick={trackATC}>
                      <span className="label">
                        {adding ? 'Adding to cart' : getButtonCaption()}
                      </span>
                      <img src={Cart} alt="cart" />
                    </StyledButton>
                  )}
                </ChooseOffer>
                {
                  // !openOffers && !offerChose && (
                  //   <SaveMoneyText>
                  //     <img src={BedSvg} />
                  //     <p>Save up to&nbsp;</p>
                  //     <span>
                  //       {packs[packs.length - 1].ribbonText.split(" ")[0]}
                  //     </span>
                  //     {/* <a href="/bundles">See our bandles</a>  */}
                  //   </SaveMoneyText>
                  // )
                }
                <PaymentIcons />
              </SelectorContainer>
              {fenixData[0]?.response && (
                <FenixResponse>
                  <img src={truck} alt="transport" />
                  <div>
                    <p>
                      For Delivery:
                      <span style={{ color: '#27B61B', fontWeight: 'bold' }}>
                        {' '}
                        {getFullDay(
                          fenixData[0]?.formattedDeliveryDate.split(', ')[0]
                        )}
                      </span>
                    </p>
                    <p>
                      Order within
                      <span style={{ color: '#FFAA00', fontWeight: 'bold' }}>
                        {' '}
                        {fenixData[0]?.hours > 0 &&
                          `${fenixData[0]?.hours}hrs`}{' '}
                        {fenixData[0]?.minutes > 0 &&
                          `${fenixData[0]?.minutes}mins`}
                      </span>
                    </p>
                  </div>
                </FenixResponse>
              )}
            </FenixContainer>
            {showBuyNowButton &&
              <BuyItNowButton id="buy-it-now">
                <div className="icons">
                  <img class="image-desktop" src={PaymentIcon} />
                  <img class="image-mobile" src={PaymentIconMobile} />
                </div>
                <div className="button">
                  <CheckoutButton id="buy-it-now-button" onClick={openCheckout}>Buy it now</CheckoutButton>
                </div>
              </BuyItNowButton>
            }
          </>
        ) : (
          <OutOfStock>Out of stock</OutOfStock>
        )}

        <DeliveryTime
          showDeliveryTimeWidget={showDeliveryTimeWidget}
        />

        {quantityText && (
          <QuantityHeader>
            <div className="header-subheader">
              <span>{quantityText}</span>
            </div>
          </QuantityHeader>
        )}

        {/* {availBundles?.length ? (
        <>
          <BundleHeader>
            <Image imageRef={bundleIcon} width={36} />
            <div className="header-subheader">
              <span>{bundleText}</span>
              {bundleSubheader && (
                <BundleSubheader>
                  <span>{bundleSubheader}</span>
                </BundleSubheader>
              )}
            </div>
          </BundleHeader>
        </>
      ) : (
        ''
      )} */}

        {enableProdQuantity && <QuantityContainer>
          {availableQuantity?.length > 1 &&
            availableQuantity.map((item: any, index) => {
              const discountedCost = item?.salesPrice || (index + 1) * price;

              if (item?.hideBox) return<></>
              return (
                <>
                  <QuantityCard
                    key={item._key}
                    isActive={index + 1 === quantity}
                    increaseHeight={item?.saveMoneyAddText}
                    onClick={() => {
                      setCurrentDiscount(item?.discount);
                      setQuantity(index + 1);
                      setDiscount(item?.discount);
                      setComparePrice(
                        parseFloat((100 / (100 - item.discount)) * price).toFixed(2)
                      );
                    }}
                    className={`${isUK && (slug?.current == 'easy-sleeper' || slug?.current == 'the-original-groove-adjustable-pillow') ? "hidden" : ""}`}
                  >
                    {item.isPopular && (
                      <Badge
                        backgroundColor={item.backgroundColor?.value}
                        isPopular
                      >
                        Popular
                      </Badge>
                    )}
                    {item.isBestValue && (
                      <Badge
                        backgroundColor={item.backgroundColor?.value}
                        isPopular={false}
                      >
                        Best Value
                      </Badge>
                    )}
                    <QuantityWrap>
                      <div>
                        <h2>
                          <strong>{item.name}</strong>
                        </h2>
                      </div>
                    </QuantityWrap>
                    <QuantityWrap>
                      <div className="WrapContain">
                        {item?.discount > 0 &&
                          <div className={`bundleDiscount`}>{item.discount}% OFF</div>}
                        <div className="priceContainer">
                          {(item?.originPrice) >
                            (discountedCost) && (
                              <div className="bundleOriginPrice">
                                <span>{process.env.GATSBY_CURRENCY_SYMBOL}</span>
                                {parseFloat(item?.originPrice).toFixed(2)}
                              </div>
                            )}
                          <div className="bundleDiscountedPrice">
                            <span>{process.env.GATSBY_CURRENCY_SYMBOL}</span>
                            {parseFloat(discountedCost).toFixed(2)}
                          </div>
                        </div>
                      </div>
                    </QuantityWrap>
                    {item?.savingPrice > 0 && (
                      <div className="saveMoney">
                        <span>
                          You save {process.env.GATSBY_CURRENCY_SYMBOL}
                          {parseFloat(item?.savingPrice).toFixed(2)}
                        </span>
                        {(discountedCost >= 50 && process.env.GATSBY_CURRENCY_SYMBOL != '$') &&

                          <span className='freeShippingStyle'>+ Get FREE Shipping</span>}
                      </div>
                    )}
                  </QuantityCard>
                  {isUK && (slug?.current == 'easy-sleeper' || slug?.current == 'the-original-groove-adjustable-pillow') && 
                    <QuantityCard
                      key={item._key}
                      isActive={index + 1 === quantity}
                      increaseHeight={item?.saveMoneyAddText}
                      onClick={() => {
                        setCurrentDiscount(item?.discount);
                        setQuantity(index + 1);
                        setDiscount(item?.discount);
                        setComparePrice(
                          parseFloat((100 / (100 - item.discount)) * price).toFixed(2)
                        );
                      }}
                      className={`quantity-card-${index} quantity-card`}
                    >
                      {item.isPopular && (
                        <Badge
                          className='badge'
                          backgroundColor={item.backgroundColor?.value}
                          isPopular
                        >
                          Popular
                        </Badge>
                      )}
                      {item.isBestValue && (
                        <Badge
                          className='badge'
                          backgroundColor={item.backgroundColor?.value}
                          isPopular={false}
                        >
                          Best Value
                        </Badge>
                      )}
                      <QuantityWrap>
                        <div>
                          <h2 className="headerVariantB">
                            <strong>{item.name}</strong>
                          </h2>
                        </div>
                      </QuantityWrap>
                      <QuantityWrap>
                        <div className="WrapContain">
                          {item?.discount > 0 &&
                            <div className={`bundleDiscount`}>{item.discount}% OFF</div>}
                          <div className="priceContainer">
                            {(item?.originPrice) >
                              (discountedCost) && (
                                <div className="bundleOriginPrice">
                                  <span>{process.env.GATSBY_CURRENCY_SYMBOL}</span>
                                  {parseFloat(item?.originPrice).toFixed(2)}
                                </div>
                              )}
                            <div className="bundleDiscountedPrice">
                              <span>{process.env.GATSBY_CURRENCY_SYMBOL}</span>
                              {parseFloat(discountedCost).toFixed(2)}
                            </div>
                          </div>
                        </div>
                      </QuantityWrap>
                      {item?.savingPrice > 0 && (
                        <div className="saveMoney">
                          <span>
                            Save {process.env.GATSBY_CURRENCY_SYMBOL}
                            {parseFloat(item?.savingPrice).toFixed(2)}
                            {(discountedCost >= 50 && process.env.GATSBY_CURRENCY_SYMBOL != '$') && "+ Free Shipping"}
                          </span>
                        </div>
                      )}
                    </QuantityCard>
                  }
                </>
              );
            })}
        </QuantityContainer>}

        {slug?.current === "easy-sleeper" &&
        <div id="mobileTestimonial">
          <CustomerTestimonial />
          </div>}

        {enableProdBundles &&

          <BundleContainer id="new-bundle-experiment">
            {availBundles?.length
              ? availBundles.map((bundle: any, index) => {
                const isActive = activeBundle?._key === bundle._key;
                return (
                  <NewBundleCard
                    key={bundle._key}
                  // isActive={index + 1 === quantity}
                  // increaseHeight={bundle?.saveMoneyAddText}
                  // onClick={() => {
                  //   setQuantity(index + 1);
                  //   setDiscount(bundle?.discount);
                  // }}
                >
                  <BundleWrap
                    isPopular={bundle?.isPopular}
                    isBestValue={bundle?.isBestValue}
                    isOpened={openBundle===bundle?._key}
                  >
                    {/* <RadioIndicator active={isActive} /> */}
                    <div className="bundleDesc">
                      {openBundle === bundle?._key}
                      <h2 className='productTitlePhone'>{bundle?.title}</h2>
                      <div className='bundleTitlePrice'>
                        <div className="titleSubtitleBundle">
                          <h2>{bundle?.title}</h2>
                          {/*<p className='bundle-subtitle'>{bundle?.subtitle}</p>*/}
                          {(openBundle === bundle?._key || openBundle !== bundle?.key) &&
                              <div className={'bundle-titles-wrapper'}>
                                {bundle?.bundles?.map((data: string, idx: number) => {
                                
                                  return (
                                      <div key={idx} className={"bundle-product-wrapper"}>
                                        <div>
                                          <Icon name={'bundle-tick'}/>
                                        </div>
                                        <p className="bundle-product-title fs-14"><strong>{data?.productTitle.match(/\d+\sx/g)} &nbsp; </strong>{ data?.productTitle.split(/\d+\sx/g)[1]}</p>
                                      </div>
                                  );
                                })}
                              </div>
                          }
                          {/* <div className={"bundle-product-wrapper"}>
                            <div>
                              <Icon name={'bundle-tick'}/>
                            </div>
                            <p className="bundle-product-title fs-14"><strong>1 x &nbsp; </strong>Free Gift</p>
                            </div> */}
                        </div>
                        <div className="priceContainer">
                          <div className={'priceContainerWrapper'}>
                            <div className="bundleOriginPrice">
                              <span style={{fontWeight: "600"}}>{process.env.GATSBY_CURRENCY_SYMBOL}</span>
                              {parseFloat(bundle?.price).toFixed(2)}
                            </div>
                            <div className="bundleDiscountedPrice">
                              <span style={{fontWeight: "600"}}>{process.env.GATSBY_CURRENCY_SYMBOL}</span>
                              {parseFloat(bundle.discountedPrice).toFixed(2)}
                            </div>
                            <div className="bundleYouSavePrice">
                              Save
                              <span style={{fontWeight: "600"}}> {process.env.GATSBY_CURRENCY_SYMBOL}</span>
                              {parseFloat(bundle.saveMoneyPrice).toFixed(2)}
                            </div>
                          </div>
                          <div className='information-button-3'>
                              <div className='add-to-cart-btn'>
                                {!openOffers && (
                                    <StyledButton                     onClick={() => handleSubmitBundle(bundle.shopifyId)}
                                                                      disabled={!bundle?.isActive} >
                                <span className="label">
                                  {getButtonCaption()}
                                </span>
                                      <img src={Cart} alt="cart" />
                                    </StyledButton>
                                )}
                              </div>
                              {openBundle !== bundle?._key  &&
                                <div onClick={() => {
                                    toggle(bundle?._key)} } className="bundle-text">WHAT'S INCLUDED?
                                </div>
                              }
                            </div>
                            <div className={`bundleDiscountSecond`}>
                              {bundle.discount}% OFF
                            </div>


                        </div>
                      </div>
                      <div className='information-button'>
                            <div className='bundle-info'>
                            <div className='information-button-2'>
                              <div className='add-to-cart-btn'>
                                {!openOffers && (
                                    <StyledButton                     onClick={() => handleSubmitBundle(bundle.shopifyId)}
                                                                      disabled={!bundle?.isActive} >
                                <span className="label">
                                  {getButtonCaption()}
                                </span>
                                      <img src={Cart} alt="cart" />
                                    </StyledButton>
                                )}
                              </div>
                              {openBundle !== bundle?._key  &&
                                <div onClick={() => {
                                    toggle(bundle?._key)} } className="bundle-text">WHAT'S INCLUDED??
                                </div>
                              }
                            </div>
          
                              {
                                  openBundle === bundle?._key  && <>
                                    {bundle?.bundles?.map((data: string, idx: number) => {
                                      return (
                                          <div key={idx}>
                                            <p className="bundle-product-title">{data?.productTitle}
                                              <div style={{display: "flex"}}>
                                                {data?.productComparePrice &&
                                                    <span className="bundle-product-price" style={{textDecoration: "line-through", color: "#9F9F9F" }} > <span>{process.env.GATSBY_CURRENCY_SYMBOL}</span>{parseFloat(data?.productComparePrice).toFixed(2)}</span>}
                                                {data?.freePRoduct ? <span className="bundle-product-price">FREE</span> : <>
                                                  {data?.productPrice &&
                                                      <span className="bundle-product-price"> <span>{process.env.GATSBY_CURRENCY_SYMBOL}</span>{parseFloat(data?.productPrice).toFixed(2)}</span>}
                                                </>}
                                              </div>
                                            </p>
                                            <p className="bundle-product-subtitle">{data?.productDescription}</p>
                                          </div>
                                      );
                                    })}
                                    {process.env.GATSBY_CURRENCY_SYMBOL == "£" &&
                                        <div className='bundle-message'>
                                          <img src={NewGift} /><p><span>FREE GIFT!</span><br/>Order today and get an extra pillow for free (worth £14.95)</p>
                                        </div>}
{/* 
                                    <div className='bundle-prices'>
                                      <p className='bundle-prod-rrp'>  <span style={{fontWeight: "600"}}>{process.env.GATSBY_CURRENCY_SYMBOL}</span>{parseFloat(bundle?.price).toFixed(2)}</p>
                                      <p className='bundle-prod-you-save'>You save   <span style={{fontWeight: "600"}}>{process.env.GATSBY_CURRENCY_SYMBOL}</span>{parseFloat(bundle.saveMoneyPrice).toFixed(2)}</p>
                                      <p className='bundle-prod-offer-price'> Offer price <span style={{fontWeight: "600"}}>{process.env.GATSBY_CURRENCY_SYMBOL}</span>{parseFloat(bundle.discountedPrice).toFixed(2)}</p>

                                    </div> */}
                                    <div onClick={() => setOpenBundle(!openBundle)} className="bundle-text-opened">Show less</div>
                                  </>
                              }
                            </div>
                          </div>
                    </div>
                  </BundleWrap>

                  <BundleWrap
                    isPopular={bundle?.isPopular}
                    isBestValue={bundle?.isBestValue}
                  >
                    <div className="WrapContain">
                      {bundle?.isPopular && (
                          <BadgeBundle
                              backgroundColor={bundle?.backgroundColor?.value}
                              isPopular={true}
                          >
                            Most Popular
                          </BadgeBundle>
                      )}
                      {bundle?.isBestValue && (
                          <BadgeBundle
                              backgroundColor={bundle?.backgroundColor?.value}
                              isPopular={false}
                          >
                            Best Value
                          </BadgeBundle>
                      )}
                      <div className={`bundleDiscount`}>
                        {bundle.discount}% OFF
                      </div>
                    </div>
                    {/* {bundle.saveMoney && (
                      <div className="saveMoney">
                        <span>
                          You save {process.env.GATSBY_CURRENCY_SYMBOL}
                          {bundle.saveMoneyPrice}
                        </span>
                        {bundle?.saveMoneyAddText && (
                          <div className="freeShipping">
                            {bundle?.saveMoneyAddText}
                          </div>
                        )}
                      </div>
                    )} */}
                    </BundleWrap>
                  </NewBundleCard>
                );
              })
              : ''}
          </BundleContainer>}

        {/* {availPillowcases?.length ? (
        <>
          <CaseHeader>
            <div className="header-subheader">
              <span>{caseText}</span>
              {caseSubheader && (
                <CaseSubheader>
                  <PortableText blocks={caseSubheader} />
                </CaseSubheader>
              )}
            </div>
          </CaseHeader>
        </>
      ) : (
        ''
      )} */}

        {/* <CaseContainer>
        {availPillowcases?.length > 0
          ? availPillowcases.map((pillow: any) => {
              const { content } = pillow;
              const isActive = activePillow?._key === pillow._key;
              const canAdd =
                pillow?.content?.shopify?.defaultVariant?.inventoryQuantity > 0;
              return (
                <CaseCard
                  key={pillow._key}
                  isActive={isActive}
                  increaseHeight={pillow?.saveMoneyAddText}
                  onClick={(e) => {
                    if (
                      seeMaterialRef &&
                      seeMaterialRef.current &&
                      !activePillow
                    ) {
                      if (
                        seeMaterialRef.current.innerHTML === e.target.innerHTML
                      ) {
                        openPillowcaseMaterial(pillow._id);
                      } else {
                        canAdd && handlePillow(pillow);
                      }
                    } else if (
                      activePillow &&
                      addPillowcaseQuantityRef &&
                      addPillowcaseQuantityRef.current &&
                      removePillowcaseQuantityRef &&
                      removePillowcaseQuantityRef.current
                    ) {
                      if (addPillowcaseQuantityRef.current.contains(e.target)) {
                        addPillowcaseQuantity();
                      } else if (
                        removePillowcaseQuantityRef.current.contains(e.target)
                      ) {
                        removePillowcaseQuantity();
                      }
                    }
                  }}
                >
                  <CardImageContainer>
                    <Image
                      imageRef={content?.shopify?.image}
                      width={!breakpoints.tablet ? 47 : 56}
                      height={!breakpoints.tablet ? 47 : 56}
                    />
                  </CardImageContainer>
                  <CardDetail>
                    <CaseTitle>
                      {content?.shopify?.defaultVariant?.title}
                    </CaseTitle>
                    <CasePrice>
                      {process.env.GATSBY_CURRENCY_SYMBOL}
                      {pillowcaseQuantity === 0
                        ? content?.shopify?.defaultVariant?.price
                        : activePillowcase.variantId ===
                          content?.shopify?.defaultVariant?.variantIdShopify
                        ? parseFloat(
                            content?.shopify?.defaultVariant?.price *
                              pillowcaseQuantity
                          ).toFixed(2)
                        : content?.shopify?.defaultVariant?.price}
                    </CasePrice>
                    <CaseLink
                      ref={seeMaterialRef}
                      // onClick={() => openPillowcaseMaterial(pillow._id)}
                    >
                      see material benefits
                    </CaseLink>
                  </CardDetail>
                  {activePillowcase?.isOpen &&
                    activePillowcase?.variantId ===
                      pillow?.content?.shopify?.defaultVariant?.variantIdShopify && (
                      // <InputQuantity
                      //   type="number"
                      //   defaultValue={1}
                      //   max={activePillowcase?.quantity}
                      //   onChange={handlePillowcaseQuantity}
                      // />
                      <Quantity>
                        <QuantityButton
                          type="button"
                          ref={removePillowcaseQuantityRef}
                          // onClick={removePillowcaseQuantity}
                        >
                          <Icon name="minus" />
                        </QuantityButton>
                        <NumberInput
                          isQuantity={true}
                          type="number"
                          name="quantity"
                          readOnly={true}
                          defaultValue={1}
                          value={pillowcaseQuantity}
                          min="1"
                          max={activePillowcase?.quantity}
                        />
                        <QuantityButton
                          type="button"
                          // onClick={addPillowcaseQuantity}
                          ref={addPillowcaseQuantityRef}
                        >
                          <Icon name="plus" />
                        </QuantityButton>
                      </Quantity>
                    )}

                </CaseCard>
              );
            })
          : ''}
      </CaseContainer> */}

        {/* {!openOffers && (
        <CaseAtcButtonWrapper>
          <StyledButton type="submit" onClick={trackATC}>
            <span className="label">
              {adding ? 'Adding to cart' : getButtonCaption()}
            </span>
            <img src={Cart} alt="cart" />
          </StyledButton>
        </CaseAtcButtonWrapper>
      )} */}

        <UpsellPopup
          isOpen={upsells?.open}
          boughtItem={upsells?.boughtItem}
          productList={upsells?.productList}
          reviews={upsellReviews}
          header={activeUpsell?.header}
          subHeader={activeUpsell?.subHeader}
        />
      </StyledForm>
    </>
  );
};

const RadioIndicator = ({ active = false }) => (
  <svg viewBox="0 0 20 20" width="20">
    {active && <circle cx="10" cy="10" fill="currentColor" r="4.5"></circle>}
    <circle
      cx="10"
      cy="10"
      r="8.25"
      fill="none"
      strokeWidth="1.8"
      stroke="currentColor"
    />
  </svg>
);

RadioIndicator.propTypes = {
  active: PropTypes.bool.isRequired,
};

export default ProductForm;
